@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300 800;
  src: local(''), url('/fonts/Inter.woff2') format('woff2'),
    url('/fonts/Inter.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'NeueBit';
  src: url('/fonts/NeueBit-Bold.woff2') format('woff2'),
    url('/fonts/NeueBit-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jaune';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/NaNJauneTRIAL-MidiMedium.woff2') format('woff2');
}

@font-face {
  font-family: 'Jaune';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/NaNJauneTRIAL-MidiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Jaune';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('/fonts/NaNJauneTRIAL-MidiBlack.woff2') format('woff2');
}
