@tailwind base;
@tailwind components;
@tailwind utilities;

@custom-selector :--heading h1, h2, h3, h4, h5, h6;

:root {
  --body-letter-spacing: -0.018rem;

  --heading-letter-spacing: -0.12rem;
  --heading-line-height: 1.2;
  --heading-font-weight: 600;

  --large-padding: 1.5rem;
  --small-padding: 1.2rem;
  --small-padding-minus: -1.2rem;

  --article-grid: repeat(1, 1fr);
  --article-grid-gap: 2rem;
  --article-padding: 0rem;

  --colour-mobile-nav-background: hsl(0, 0%, 0%);
  --colour-mobile-nav-text: hsl(0, 0%, 100%);
  --colour-form-input: #050505;
  --colour-form-tag: #282828;
  --icon-search: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 9.84871 15.3729 11.551 14.3199 12.9056L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L12.9056 14.3199C11.551 15.3729 9.84871 16 8 16C3.58172 16 0 12.4183 0 8Z' fill='white'/%3E%3C/svg%3E%0A");
}

@media (width >= 600px) {
  :root {
    --large-padding: 3rem;
    --small-padding: 1.5rem;
    --small-padding-minus: -1.5rem;
  }
}

@media (width >= 900px) {
  :root {
    --large-padding: 6rem;
    --small-padding: 3rem;
    --small-padding-minus: -3rem;

    --article-grid: repeat(3, 1fr);
    --article-padding: 2rem;
  }
}

@media (width >= 1200px) {
  :root {
    --article-grid-gap: 4rem;
  }
}

@media (width >= 1800px) ) {
  :root {
    --article-padding: 4rem;
  }
}

#__next {
  @apply flex flex-col;
  @apply min-h-screen;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
  scroll-padding-top: 180px;

  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;

  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

* {
  @apply box-border;
}

body {
  @apply bg-white;
  @apply font-body;
  @apply font-normal;
  @apply m-0;
  @apply no-underline;
  @apply normal-case;
  @apply overflow-x-hidden;
  @apply text-white;
  @apply leading-7;

  letter-spacing: var(--body-letter-spacing);
  /* font-feature-settings: 'case' 0, 'cpsp', 'dlig' 0, 'frac', 'dnom' 0, 'numr' 0,
    'salt' 0, 'subs' 0, 'sups' 0, 'tnum', 'zero' 0, 'ss01' 0, 'ss02' 0, 'ss03',
    'ss04' 0, 'cv01', 'cv02', 'cv03', 'cv04', 'cv05', 'cv06' 0, 'cv07' 0, 'cv08',
    'cv09', 'cv10', 'cv11' 0, 'calt', 'ccmp', 'kern'; */
  font-variant-ligatures: contextual common-ligatures;
}

body.body-lock {
  @apply overflow-hidden;
}

.light {
  @apply text-white;
  @apply border-white;
}

body .default-padded {
  padding-left: var(--small-padding);
  padding-right: var(--small-padding);
}

.blur {
  backdrop-filter: blur(1px);
}

::selection {
  @apply bg-primary;
  @apply text-dark;
}

p {
  @apply mb-4;
  @apply -tracking-[0];
}

.default-title {
  line-height: 0.9;
  @apply font-display;
  @apply font-black;
  @apply uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-semibold;
  @apply tracking-[0.008rem];
  @apply leading-tight;
  @apply mt-12 mx-0 mb-5;
}

h1 {
  @apply mt-0;
  font-size: clamp(1rem, -0.875rem + 8.333vw, 5.653rem);
  /* 5.653rem; */
}

h2 {
  font-size: clamp(1rem, -0.875rem + 8.333vw, 3.998rem);
  /* 3.998rem */
}

h3 {
  font-size: 2.827rem;
}

h4 {
  @apply font-display;
    @apply text-[1.85rem];
}

h5 {
  font-size: 1.414rem;
}

small,
.text_small {
  font-size: 0.707rem;
}

a {
  @apply text-white;
}

svg {
  @apply fill-white;
}

/* TOC styles */
li.heading-4 {
  padding-left: 20px;
}

li.heading-5 {
  padding-left: 40px;
}

.form-alert{
  font-family: Jaune, sans-serif !important;
  font-size: 1.85rem !important;  
  font-weight: 600 !important;
  margin-bottom: 1.25rem !important;
}

.form-message{
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 1.25rem;
}

.marquee {
  justify-content: space-around;
  gap: 20px;
}

/* Swiper Styles */
.swiper-pagination-progressbar.swiper-pagination-progressbar
  .swiper-pagination-progressbar-fill {
  @apply bg-white;
}

.swiper-horizontal.swiper-horizontal > .swiper-pagination-progressbar {
  top: initial;
  @apply bottom-0;
}

/* Font bit class */
.fontPixel {
  @apply font-pixel;
}

/* visually hidden */
.visually-hidden {
  @apply inline-block;
  @apply absolute;
  @apply overflow-hidden;
  @apply h-[1px] w-[1px];
  @apply p-0;
  @apply border-0;
  @apply -m-[1px];

  clip: rect(0 0 0 0);
}

/* algolia overides */
.ais-Pagination-item--selected {
  @apply bg-tertiary;
  @apply p-5;
  @apply rounded-[100px];
  @apply w-8 h-8;
}

.dark-nav {
  fill: #181818;
  color: #181818 !important;
  background: #181818 !important;
}

.light-nav {
  fill: #fff;
  color: #fff !important;
  background: #fff !important;
}

/* gsap resets */
#mr-bag-man {
  transform: translateY(200%);

  /* opacity: 0;
  visibility: hidden; */
}


.reveal{
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}

.karmoon-spark {
  transform: translateX(100%);
  /* opacity: 0;
  visibility: hidden; */
}

.open {
  @apply max-h-[2000px];
  @apply transition-all duration-300 ease-in-out;
}

.closed {
  @apply max-h-0;
  @apply transition-all duration-300 ease-in-out;
}

.newsletter__template {
font-family : 'Inter', Arial, sans-serif !important ;
}
.newsletter__template html, .newsletter__template body {
margin : 0 auto !important ;
padding : 0 !important ;
height : 100% !important ;
width : 100% !important ;
background : #ffffff;
}
@media only screen and (max-width: 600px) {
.newsletter__template body {
padding-left : 1rem !important ;
padding-right : 1rem !important ;
}
}
.newsletter__template div[style*="margin: 16px 0"] {
margin : 0 !important ;
}
.newsletter__template table {
border-spacing : 0 !important ;
border-collapse : collapse !important ;
table-layout : fixed !important ;
margin : 0 auto !important ;
}
.newsletter__template a {
text-decoration : none;
}
.newsletter__template *[x-apple-data-detectors], .newsletter__template .unstyle-auto-detected-links *, .newsletter__template .aBn {
border-bottom : 0 !important ;
cursor : default !important ;
color : inherit !important ;
text-decoration : none !important ;
font-size : inherit !important ;
font-family : inherit !important ;
font-weight : inherit !important ;
line-height : inherit !important ;
}
.newsletter__template .a6S {
display : none !important ;
opacity : 0.01 !important ;
}
.newsletter__template .im {
color : inherit !important ;
}
.newsletter__template img.g-img + div {
display : none !important ;
}
@media only screen and (device-width) and (device-width) {
.newsletter__template u ~ div .email-container {
min-width : 320px !important ;
}
}
@media only screen and (device-width) and (device-width) {
.newsletter__template u ~ div .email-container {
min-width : 375px !important ;
}
}
@media only screen and (device-width) {
.newsletter__template u ~ div .email-container {
min-width : 414px !important ;
}
}
.newsletter__wrapper * {
color : #181818 !important ;
font-family : "Inter", Arial, sans-serif !important ;
letter-spacing : 0 !important ;
margin : 0;
padding : 0;
}
.newsletter__wrapper h1 {
font-size : 48px !important ;
letter-spacing : -0.04rem !important ;
font-weight : 800 !important ;
}
.newsletter__wrapper h2 {
letter-spacing : -0.03rem !important ;
font-size : 28px;
font-weight : 800;
line-height : 1.5;
}
.newsletter__wrapper h3, .newsletter__wrapper h4, .newsletter__wrapper h5, .newsletter__wrapper h6 {
letter-spacing : -0.02rem !important ;
font-weight : 700 !important ;
font-size : 18px !important ;
}
.newsletter__wrapper ul {
margin : 0;
padding : 0;
}
.newsletter__wrapper p, .newsletter__wrapper li {
font-size : 14px !important ;
line-height : 1.4 !important ;
}
@media only screen and (min-width: 600px) {
.newsletter__wrapper p, .newsletter__wrapper li {
font-size : 15px !important ;
}
}
.newsletter__wrapper hr {
height : 1px;
margin : 2rem 0;
background-color : #efefef;
}
.newsletter__wrapper .description {
margin-bottom : 1.25rem;
}
.newsletter__container {
width : 100%;
margin : 0 auto;
padding : 0 0.5rem;
max-width : 600px;
}
@media only screen and (min-width: 600px) {
.newsletter__container {
max-width : 660px;
}
}
.newsletter__container .contain {
display : block;
padding-left : 2rem;
padding-right : 2rem;
}
.newsletterClientNews__wrapper {
padding-top : 2.5rem !important ;
padding-bottom : 2.5rem !important ;
display : block;
background-color : #ebf2ff;
position : relative;
}
.newsletterClientNews__wrapper h2, .newsletterClientNews__wrapper p {
position : relative;
z-index : 1;
}
@media only screen and (min-width: 768px) {
}
.newsletterClientNews__wrapper .post {
position : relative;
z-index : 1;
}
.newsletterClientNews__wrapper .post .post__image img {
width : 100%;
height : auto;
position : relative;
display : block;
}
.newsletterClientNews__wrapper .post .post__text {
padding-left : 20px;
display : block;
width : 100%;
}
.newsletterClientNews__wrapper .post .post__text h4, .newsletterClientNews__wrapper .post .post__text h4 a {
margin-bottom : 5px;
letter-spacing : -0.5px !important ;
}
.newsletterClientNews__wrapper .post .post__text p {
font-size : 14px !important ;
}
.newsletterClientNews__wrapper .post .post__text a {
font-weight : 700;
margin-top : 5px;
display : block;
}
.newsletterDiscovery__wrapper {
padding-top : 2.5rem !important ;
padding-bottom : 2.5rem !important ;
display : block;
background-color : #000000;
position : relative;
}
.newsletterDiscovery__wrapper h2, .newsletterDiscovery__wrapper p {
position : relative;
z-index : 1;
}
.newsletterDiscovery__wrapper h2 {
color : #ffebf2 !important ;
text-transform : uppercase;
text-align : center;
display : block;
margin : 0;
padding : 0 4rem;
font-size : 40px;
line-height : 1;
}
.newsletterDiscovery__wrapper p, .newsletterDiscovery__wrapper a {
color : #ffffff !important ;
margin : 0 auto !important ;
display : block;
text-align : center;
}
@media only screen and (min-width: 768px) {
}
.newsletterEcom__wrapper {
padding-top : 2.5rem !important ;
padding-bottom : 2.5rem !important ;
display : block;
border-bottom : 1px solid #eaeaea;
}
.newsletterEcom__wrapper ul {
padding-left : 1rem;
list-style : disc;
}
.newsletterEcom__wrapper li {
line-height : 1.4;
margin-bottom : 1rem;
font-size : 14px;
}
.newsletterEcom__wrapper li a {
font-weight : 700;
}
.newsletterFooter__wrapper {
padding-top : 2.5rem !important ;
padding-bottom : 2.5rem !important ;
display : block;
}
.newsletterFooter__wrapper p {
font-size : 14px !important ;
margin-top : 1.25rem;
margin-bottom : 1.25rem;
}
.newsletterFooter__wrapper p strong {
font-weight : 700;
display : block;
margin : 0 0 0.25rem !important ;
padding : 0 !important ;
line-height : 1.4 !important ;
}
.newsletterFooter__wrapper .sender_line {
display : none;
}
.newsletterHeader__wrapper {
padding : 1rem 2rem !important ;
display : block;
}
.newsletterHeader__wrapper svg {
width : 100%;
max-width : 50px;
}
@media only screen and (min-width: 600px) {
.newsletterHeader__wrapper svg {
max-width : 60px;
}
}
.newsletterHeader__wrapper ul {
float : right;
list-style : none;
margin-top : -5px;
line-height : 1;
}
.newsletterHeader__wrapper li {
display : inline-block;
margin : 0 0 0 1rem;
line-height : 1;
padding : 0;
}
.newsletterHeader__wrapper li a {
color : #181818;
font-size : 11px;
letter-spacing : 2px !important ;
text-transform : uppercase;
font-weight : 600;
}
@media only screen and (min-width: 600px) {
.newsletterHeader__wrapper li a {
font-size : 13px;
letter-spacing : 1.25px;
}
}
.newsletterHero__wrapper {
padding : 2.5rem 0 0 !important ;
display : block;
}
.newsletterHero__wrapper img {
width : 100%;
height : auto;
border-radius : 15px;
position : relative;
}
.newsletterHotLinks__wrapper {
padding-top : 2.5rem !important ;
padding-bottom : 2.5rem !important ;
display : block;
border-bottom : 1px solid #eaeaea;
}
.newsletterHotLinks__wrapper ul {
padding-left : 1rem;
list-style : disc;
}
.newsletterHotLinks__wrapper li {
line-height : 1.4;
margin-bottom : 1.25rem;
font-size : 14px;
}
.newsletterHotLinks__wrapper li a {
font-weight : 700;
}
.newsletterIntro__wrapper {
padding : 2rem 0 0 !important ;
display : block;
}
.newsletterIntro__wrapper h1 {
font-size : 30px;
line-height : 1;
font-weight : 800;
margin-top : 2.5rem;
margin-bottom : 0.35rem;
}
@media only screen and (min-width: 600px) {
.newsletterIntro__wrapper h1 {
font-size : 40px !important ;
}
}
.newsletterIntro__wrapper .date {
font-size : 13px;
font-weight : 400;
color : #999999 !important ;
margin : 0;
padding : 0;
}
.newsletterLiquid__wrapper {
padding-top : 2.5rem !important ;
padding-bottom : 2.5rem !important ;
display : block;
}
.newsletterLiquid__wrapper .snippet_image {
width : 100%;
display : block;
height : auto;
border-radius : 15px;
margin-top : 1.25rem;
}
.newsletterLiquid__wrapper pre {
font-family : monospace !important ;
font-size : 12px;
line-height : 1.5;
white-space : pre-wrap;
margin : 1rem -1rem 0 -1rem;
padding : 1rem;
background-color : #f5dfe6;
border-radius : 15px;
}
.newsletterOutro__wrapper {
padding-top : 2.5rem !important ;
padding-bottom : 2.5rem !important ;
display : block;
}
.newsletterOutro__wrapper p {
font-size : 12px !important ;
color : #555555 !important ;
}
@media only screen and (min-width: 600px) {
.newsletterOutro__wrapper p {
font-size : 13px !important ;
}
}
.newsletterPosts__wrapper {
padding-top : 2.5rem !important ;
padding-bottom : 2.5rem !important ;
display : block;
border-bottom : 1px solid #eaeaea;
}
.newsletterPosts__wrapper .post {
padding-bottom : 10px;
display : inline-flex;
}
.newsletterPosts__wrapper .post .post__image img {
width : 100%;
height : auto;
position : relative;
display : block;
}
.newsletterPosts__wrapper .post .post__image.last {
padding-bottom : 0;
}
.newsletterPosts__wrapper .post .post__text {
padding-left : 20px;
display : block;
width : 100%;
}
.newsletterPosts__wrapper .post .post__text h4, .newsletterPosts__wrapper .post .post__text h4 a {
margin-bottom : 5px;
letter-spacing : -0.5px !important ;
}
.newsletterPosts__wrapper .post .post__text p {
font-size : 14px !important ;
}
.newsletterPosts__wrapper .post .post__text a {
font-weight : 700;
margin-top : 5px;
display : block;
}